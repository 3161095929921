import revive_payload_client_hxA299wc7H from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_w154KPj6yg from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EsgyHY4tSA from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_OYWCMwLDQN from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y9mHTBkk5U from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vNVn7zbOOg from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4KNkFVpo2H from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mvj85xSm4B from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_WTty1EkPYH from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@22.13.9_db0@_ac760e0d07ffe34c4d7b4b9646c3b656/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_NUEDjvgWpr from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@plan_eb948bf1711e08e202e1f1bc5f06b2d8/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_sj65RSrf0V from "/app/node_modules/.pnpm/nuxt-papa-parse@1.0.8_magicast@0.3.5_rollup@4.34.9/node_modules/nuxt-papa-parse/dist/runtime/plugin.mjs";
import plugin_ldFmqLXqB5 from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8_56bc314c1b314c94013faae7bd392089/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apexchart_gUgipXIQGn from "/app/plugins/apexchart.ts";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import click_outside_directive_YjNOGbcjIP from "/app/plugins/click-outside-directive.ts";
import custom_fetch_UFdPayo4xQ from "/app/plugins/custom-fetch.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_hxA299wc7H,
  unhead_w154KPj6yg,
  router_EsgyHY4tSA,
  payload_client_OYWCMwLDQN,
  navigation_repaint_client_Y9mHTBkk5U,
  check_outdated_build_client_vNVn7zbOOg,
  chunk_reload_client_4KNkFVpo2H,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WTty1EkPYH,
  plugin_NUEDjvgWpr,
  plugin_sj65RSrf0V,
  plugin_ldFmqLXqB5,
  apexchart_gUgipXIQGn,
  auth_vT9JWWT9pN,
  click_outside_directive_YjNOGbcjIP,
  custom_fetch_UFdPayo4xQ,
  sentry_3AyO8nEfhE,
  vuetify_7h9QAQEssH
]