// プラン毎の制限について
// renderLimit:
//   0  : 無制限
//   n>=1: レンダリング回数（例: 3なら3回まで）
//
// downloadLimit:
//   -1 : 無制限
//    0 : ダウンロード不可
//   n>=1: ダウンロード回数/月（例: 3なら1ヶ月で3回まで）

export enum planEnum {
  trial = 1,
  lite = 2,
  standard = 3,
  professional = 4,
  specialTrial = 5,
}

export enum cgBankStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  SUSPEND = 'SUSPENDED',
}

export const PLAN = {
  [planEnum.trial]: {
    id: planEnum.trial,
    label: '無料体験',
    renderLimit: 3,
    downloadLimit: 0,
    taxFree: 0,
    price: 0,
    canPartialRender: 0,
    features: [
      { isEnabled: true, label: 'レンダリング：3回' },
      { isEnabled: false, label: '画像ダウンロード : 不可' },
      { isEnabled: false, label: 'ダウンロード回数を毎月リセット' },
      { isEnabled: false, label: '部分修正依頼 : 不可' },
    ],
    explanation: 'クレジットカードの登録不要ですぐに始められるので、試しにサービスを利用してみたい方向けのプランです。',
  },
  [planEnum.lite]: {
    id: planEnum.lite,
    label: 'ライト',
    renderLimit: 0,
    downloadLimit: 0,
    taxFree: 9000,
    price: 9900,
    canPartialRender: 0,
    features: [
      { isEnabled: true, label: 'レンダリング：無制限' },
      { isEnabled: true, label: '画像のダウンロード : 別途費用' },
      { isEnabled: false, label: 'ダウンロード回数を毎月リセット' },
      { isEnabled: true, label: '部分修正依頼 : 別途費用' },
    ],
    explanation: 'レンダリングは無制限で自由に作品制作。必要な時だけダウンロードできる、柔軟な料金プランです。',
  },
  [planEnum.standard]: {
    id: planEnum.standard,
    label: 'スタンダード',
    renderLimit: 0,
    downloadLimit: 5,
    taxFree: 30000,
    price: 33000,
    canPartialRender: 1,
    features: [
      { isEnabled: true, label: 'レンダリング：無制限' },
      { isEnabled: true, label: '画像のダウンロード : 5回/月' },
      { isEnabled: true, label: 'ダウンロード回数を毎月リセット' },
      { isEnabled: true, label: '部分修正依頼 : 別途費用' },
    ],
    explanation: '高品質なレンダリングとダウンロード回数の毎月リセットにより、本格的な空間生成を継続的に利用したい方向けのプランです。',

  },
  [planEnum.professional]: {
    id: planEnum.professional,
    label: 'プロフェッショナル',
    renderLimit: 0,
    downloadLimit: -1,
    taxFree: 100000,
    price: 110000,
    canPartialRender: 1,
    features: [
      { isEnabled: true, label: 'レンダリング：無制限' },
      { isEnabled: true, label: '画像のダウンロード : 無制限' },
      { isEnabled: true, label: 'ダウンロード回数無制限' },
      { isEnabled: true, label: '部分修正依頼 : 別途費用' },
    ],
    explanation: 'レンダリング、ダウンロード共に無制限。プロフェッショナルな制作現場の効率を最大限に高めるプランです。',
  },
  [planEnum.specialTrial]: {
    id: planEnum.specialTrial,
    label: '特別トライアル',
    renderLimit: 0,
    downloadLimit: -1,
    taxFree: 100000,
    price: 110000,
    canPartialRender: 1,
    features: [
      { isEnabled: true, label: 'レンダリング：無制限' },
      { isEnabled: true, label: '画像のダウンロード : 無制限' },
      { isEnabled: true, label: 'ダウンロード回数無制限' },
      { isEnabled: true, label: '部分修正依頼 : 別途費用' },
    ],
    explanation: 'レンダリング、ダウンロード共に無制限。プロフェッショナルな制作現場の効率を最大限に高めるプランです。',
  },
}

export const TRIAL_PLANS = {
  [planEnum.trial]: PLAN[planEnum.trial], // 無料体験
  [planEnum.specialTrial]: PLAN[planEnum.specialTrial], // 特別トライアル
} as const

export const SUBSCRIPTION_PLANS = {
  [planEnum.lite]: PLAN[planEnum.lite],
  [planEnum.standard]: PLAN[planEnum.standard],
  [planEnum.professional]: PLAN[planEnum.professional],

} as const

export const getPlanById = (id: planEnum) => PLAN[id]
